

/* Customize the label (the container) */
.ModesContainer {
  display: flex;
  position: relative;
  width: 30px;
  height: 40px;
  /* padding-left: 35px;
  margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.ModesContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.ModesCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #eee;
  border-radius: 5px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.ModesCheckmark:after {
  display: block;
  content: '';
  position: absolute;
}

/* Show the indicator (dot/circle) when checked */
.ModesContainer input:checked ~ .ModesCheckmark:after {
  background-color: rgb(125, 178, 66);
}

/* Style the indicator (dot/circle) */
.ModesContainer .ModesCheckmark:after {
  top: 10px;
  left: 0px;
  width: 20px;
  height: 20px;
  border: 1px solid #777;
  border-radius: 5px;
  cursor: pointer;
}

.ModesContainer .ModesRotate:after {
  background: rgb(249, 246, 243) url('../images/icons/rotate.svg') no-repeat center;
  background-size: 80%;
}
.ModesContainer .ModesTranslate:after {
  background: rgb(249, 246, 243) url('../images/icons/move.svg') no-repeat center;
  background-size: 80%;
}
.ModesContainer .ModesLock:after {
  background: rgb(249, 246, 243) url('../images/icons/lock.svg') no-repeat center;
  background-size: 80%;
}

.ModesContainer .Anchor:after {
  background: rgb(249, 246, 243) url('../images/icons/anchor.svg') no-repeat center;
  background-size: 80%;
}
